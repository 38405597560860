'use client';

import { useEffect, useRef } from 'react';

const Player_Autoplay = () => {
  const ref = useRef<HTMLSourceElement>(null);

  useEffect(() => {
    const player = ref.current?.closest('video');
    if (player) {
      player.play();
    }
  }, []);

  return <source ref={ref} />;
};

export default Player_Autoplay;
