'use client';

import { forwardRef } from 'react';

import { thumbHashToUrl } from '../../utils/thumbhash';
import Image from './Image';

type Props = {
  thumbHash: string;
};

const ImageWithThumbHash = forwardRef(
  ({ thumbHash, ...props }: React.ComponentProps<typeof Image> & Props, ref: React.Ref<HTMLImageElement>) => {
    const thumbHashUrl = thumbHashToUrl(thumbHash);

    return <Image ref={ref} blurDataURL={thumbHashUrl} placeholder='blur' {...props} />;
  }
);

ImageWithThumbHash.displayName = 'ImageWithThumbHash';

export default ImageWithThumbHash;
