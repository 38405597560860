'use client';

import useNetworkStatus from '../../hooks/useNetworkStatus';
import clx from '../../utils/clx';
import ImageWithThumbHash from '../Image/ImageWithThumbHash';

const Placeholder = ({
  className,
  placeholder,
  thumbHash,
  children,
}: React.PropsWithChildren<{
  className?: string;
  // @ts-ignore
  placeholder?: string | StaticImageData;
  thumbHash?: string;
}>) => {
  const effectiveConnectionType = useNetworkStatus();

  if (effectiveConnectionType !== '4g') {
    return placeholder ? (
      <ImageWithThumbHash
        alt='video placeholder'
        src={placeholder}
        thumbHash={thumbHash || ''}
        fill
        className={clx('absolute left-0 top-0 -z-10 h-full w-full object-cover', className)}
        fetchPriority='high'
      />
    ) : (
      <div className={clx('absolute left-0 top-0 -z-10 h-full w-full', className)} />
    );
  }

  // @ts-ignore
  return children;
};

export default Placeholder;
