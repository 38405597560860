'use client';

import { useBackgroundImage } from './WhySection_BackgroundProvider';

const WhySection_Background = ({
  url = '',
  children,
}: React.PropsWithChildren<{
  url?: string;
}>) => {
  const forceUrl = useBackgroundImage() || url;

  return (
    <div
      className='flex h-full w-full items-end !bg-[length:auto_833px] !bg-top min-[1129px]:!bg-[length:100%_auto]'
      style={{
        background: `
            linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 15%),
            url(${forceUrl}) no-repeat
          `,
      }}
    >
      {children}
    </div>
  );
};

export default WhySection_Background;
