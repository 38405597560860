'use client';

import { createContext, use } from 'react';

const Context = createContext<string | undefined>(undefined);

const WhySection_BackgroundProvider = ({
  url,
  children,
}: React.PropsWithChildren<{
  url: string;
}>) => <Context.Provider value={url}>{children}</Context.Provider>;

export default WhySection_BackgroundProvider;

export const useBackgroundImage = () => use(Context);
