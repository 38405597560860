import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/react-wrap-balancer/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/ui/src/components/BackgroundVideo/Placeholder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/ui/src/components/Image/Image.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wander-apps/ui/src/components/Video/Player_Autoplay.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wander-apps/web/app/(with-black-background)/(landing)/components/HeroSection/hero-placeholder.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/components/LazyRender.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/features/whyWanderSection/WhySection_Background/WhySection_Background.tsx");
