'use client';

import { startTransition, useEffect, useRef, useState } from 'react';

import clx from '@wander/ui/src/utils/clx';

import onScreenObserver from '@/utils/onScreenObserver';

type Props = React.HTMLProps<HTMLDivElement> & {
  threshold?: number;
  rootMargin?: string;
  onVisible?: () => void;
  className?: string;
  wrapper?: boolean;
  loadOnScroll?: boolean;
};

const LazyRender = ({
  children,
  threshold,
  rootMargin,
  className,
  onVisible,
  wrapper = false,
  loadOnScroll = false,
  ...props
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    const observer = onScreenObserver(rootMargin, threshold);
    const unobserve = observer.onIntersection(wrapperRef.current, (isIntersecting, unobserve) => {
      if (isIntersecting) {
        setIsVisible(true);
        onVisible?.();
        unobserve();
      }
    });

    const handleOnScroll = () => {
      unobserve();
      startTransition(() => {
        setIsVisible(true);
        onVisible?.();
      });
    };

    if (loadOnScroll) {
      window.addEventListener('scroll', handleOnScroll, { once: true });
    }

    return () => {
      if (loadOnScroll) {
        window.removeEventListener('scroll', handleOnScroll);
      }
      unobserve();
    };
  }, [threshold, rootMargin, wrapperRef, loadOnScroll, onVisible]);

  if (wrapper) {
    return (
      <div ref={wrapperRef} className={clx('min-h-[800px]', className)} {...props}>
        {isVisible ? (children as JSX.Element) : undefined}
      </div>
    );
  }

  return isVisible ? children : <div ref={wrapperRef} className={clx('min-h-[800px]', className)} {...props} />;
};

export default LazyRender;
