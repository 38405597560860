const makeIntersectionObserver = () => {
  let observer: IntersectionObserver;
  const weakSubscribersCallback = new WeakMap();
  const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0;

  return (rootMargin = '0px 0px 600px 0px', threshold = 0) => {
    observer ||= new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (weakSubscribersCallback.has(entry.target)) {
            weakSubscribersCallback.get(entry.target)(isIntersecting(entry), () => {
              observer.unobserve(entry.target);
              weakSubscribersCallback.delete(entry.target);
            });
          } else {
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin,
        threshold,
      }
    );

    return {
      onIntersection: (element: HTMLElement, callback: (isIntersecting: boolean, unobserve: () => void) => void) => {
        observer.observe(element);
        weakSubscribersCallback.set(element, callback);
        return () => {
          observer.unobserve(element);
          weakSubscribersCallback.delete(element);
        };
      },
    };
  };
};

const onScreenObserver = makeIntersectionObserver();

export default onScreenObserver;
