import { useSyncExternalStore } from 'react';

type EffectiveConnectionType = 'slow-2g' | '2g' | '3g' | '4g';

declare global {
  interface Navigator {
    connection: {
      effectiveType: EffectiveConnectionType;
      addEventListener: (event: string, cb: () => void) => void;
      removeEventListener: (event: string, cb: () => void) => void;
    };
  }
}

const getSnapshot = () => {
  const unsupported = !(
    typeof navigator !== 'undefined' &&
    'connection' in navigator &&
    'effectiveType' in navigator.connection
  );

  return unsupported ? undefined : navigator.connection.effectiveType;
};

const getServerSnapshot = () => '4g';

const subscribe = (callback: () => void) => {
  const navigatorConnection = navigator?.connection;
  navigatorConnection?.addEventListener('change', callback);
  return () => {
    navigatorConnection?.removeEventListener('change', callback);
  };
};

const useNetworkStatus = (initialEffectiveConnectionType: EffectiveConnectionType | undefined = '4g') => {
  const networkStatus = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
  return networkStatus || initialEffectiveConnectionType;
};

export default useNetworkStatus;
