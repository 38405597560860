import { thumbHashToDataURL } from 'thumbhash';

const getBase64 = (str: string) =>
  typeof window !== 'undefined' ? window.atob(str) : Buffer.from(str, 'base64').toString('binary');

export function thumbHashToUrl(thumbHash: string) {
  try {
    const binaryHash = new Uint8Array(
      getBase64(thumbHash)
        .split('')
        .map((x) => x.charCodeAt(0))
    );

    return thumbHashToDataURL(binaryHash);
  } catch {
    return '';
  }
}
